<template>
  <div v-if="showDecryptedData" class="full-screen-div">
    <pre>{{ decryptedData }}</pre>
    <div class="button-container">
      <button class="btn btn-success" @click="copyToClipboard(decryptedData)">Copy Content</button> &nbsp;
      <button class="close-btn" @click="showDecryptedData = false">Close</button>
    </div>
  </div>
  <div>
    <h4 class="text-center mt-2 mb-2">Address Generator</h4>
    <h6 class="text-center mt-2 mb-2">Use your cold wallet or distribute addresses to your members.</h6>
    <div>
      <span class="d-flex justify-content-center p-2"> <button class="btn btn-outline-secondary mt-3 ms-2" @click="toggleData">{{ hideData ? "Show" : "Hide" }} </button> </span>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="card">
            <div class="card-body">
              <div v-if="error" class="alert alert-danger" role="alert">
                {{ error }}
              </div>
              <div class="form-group">
                <label for="mnemonic">Mnemonic:</label>
                <div class="input-group">
                  <input v-if="!hideData" id="mnemonic" v-model="mnemonic" class="form-control" type="text"/>
                  <input v-else class="form-control" readonly type="text" v-model="hiddenvalue"/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-primary" @click="generateMnemonic">Generate</button>
                  </div>
                </div>
              </div>

              <div class="mb-2"></div>
              <div class="row">

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="derivePath">Default Ethereum</label>
                    <input v-if="!hideData" id="derivePath" v-model="derivePath" class="form-control" type="text"/>
                    <input v-else class="form-control" readonly type="text" v-model="hiddenvalue"/>
                    <a href="https://github.com/trustwallet/wallet-core/blob/master/registry.json" target="_blank" class="text-muted">Other Coins</a>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="password">Password:</label>
                    <input v-if="!hideData" id="password" v-model="password" class="form-control" type="text"/>
                    <input v-else class="form-control" readonly type="text" v-model="hiddenvalue"/>

                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="start">Start:</label>
                    <input id="start" v-model.number="start" class="form-control" min="0" type="number"/>

                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="end">End:</label>
                    <input id="end" v-model.number="end" class="form-control" min="1" type="number"/>
                  </div>
                </div>
              </div>
              <div class="mb-2"></div>
              <div class="button-container d-flex justify-content-end">
                <button class="btn btn-outline-info mt-3 ms-2" @click="exportData">Export</button>
                <button class="btn btn-outline-danger mt-3 ms-2" data-bs-target="#decryptModal" data-bs-toggle="modal"> Decrypt </button>
                <button class="btn btn-outline-secondary mt-3 ms-2" @click="toggleData">{{ hideData ? "Show" : "Hide" }} </button>
              </div>
              <div class="mt-2">
                <label class=" fw-bold text-danger" for="bip32">Bip39 RootKey</label>
                <input v-if="!hideData"
                       :value="bip39root"
                       class="form-control mb-3"
                       readonly
                       style="font-size: 12px;"
                       type="text"
                />
                <input v-else class="form-control" readonly type="text" v-model="hiddenvalue"/>
              </div>
              <div class="mb-2"></div>

              <div class="mt-4">
                <label for="bip32">Bip39 Seed</label>
                <input v-if="!hideData"
                       :value="bip39seed"
                       class="form-control mb-3"
                       readonly
                       style="font-size: 12px;"
                       type="text"
                />
                <input v-else class="form-control" readonly type="text" v-model="hiddenvalue"/>
              </div>
              <div class="mb-2"></div>

              <div class="mt-4">
                <span for="bip32">Bip32 Extended Private Keys</span>
                <input v-if="!hideData"
                       :value="bip32extendedprivatekey"
                       class="form-control mb-3"
                       readonly
                       style="font-size: 12px;"
                       type="text"
                />
                <input v-else class="form-control" readonly type="text" v-model="hiddenvalue"/>
                <span for="bip32">Bip32 Extended Public Keys</span>
                <input v-if="!hideData" :value="bip32extendedpublickey" class="form-control" readonly style="font-size: 12px;"
                       type="text"/>
                <input v-else class="form-control" readonly type="text" v-model="hiddenvalue"/>

                <div class="mb-4"></div>

                <span for="bip44">Bip44 Extended Private Keys</span>
                <input v-if="!hideData"
                       :value="bip44extendedprivatekey"
                       class="form-control mb-3"
                       readonly
                       style="font-size: 12px;"
                       type="text"
                />
                <input v-else class="form-control" readonly type="text" v-model="hiddenvalue"/>

                <span for="bip44">Bip44 Extended Public Keys</span>
                <input v-if="!hideData" :value="bip44extendedpublickey" class="form-control" readonly style="font-size: 12px;" type="text"/>
                <input v-else class="form-control" readonly type="text" v-model="hiddenvalue"/>

                <div class="mb-4"></div>

                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Address</th>
                    <th scope="col">Privatekey</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(address, i) in addresses" :key="i">
                    <th scope="row">{{ i + start }}</th>
                    <td>
                      <div class="small">{{ address.address }}</div>
                    </td>
                    <td>
                      <div class="small">
                        <span v-if="!hideData"><input @click="copyToClipboard(address.privateKey)" style="border: none; width: 100%;" type="text" v-model="address.privateKey"> </span>
                        <span v-else style="font-size: 12px;">************************************</span>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="decryptModal" aria-hidden="false" aria-labelledby="decryptModalLabel" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="decryptModalLabel" class="modal-title">Decrypt Data</h5>
          <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="encryptedData">Encrypted Data:</label>
            <textarea id="encryptedData" v-model="encryptedData" class="form-control"></textarea>
          </div>
          <div class="form-group">
            <label for="decryptPassword">Password:</label>
            <input id="decryptPassword" v-model="decryptPassword" class="form-control" type="password"/>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
          <button class="btn btn-primary" type="button" @click="decryptData">Decrypt</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Importing necessary libraries for Ethereum and cryptographic operations.
import {ethers, utils} from "ethers";
import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      hiddenvalue: "********************************************************************************",
      mnemonic: "",
      start: 0,
      end: 4,
      derivePath: "m/44'/60'/0'/0/",
      password: "",
      addresses: [],
      bip32extendedprivatekey: "",
      bip32extendedpublickey: "",
      bip44extendedprivatekey: "",
      bip44extendedpublickey: "",
      bip39seed: "",
      bip39root: "",
      error: "",
      encryptedData: "",
      decryptedData: "",
      showDecryptedData: false,
      decryptPassword: "",
      showData: true,
      hideData: false,
      provider:'https://bnbrpc.satoshiturk.com/'
    };
  },

  // Watchers: Re-generate Ethereum addresses whenever these properties change.
  watch: {
    password: "generateAddresses",
    mnemonic: "generateAddresses",
    derivePath: "generateAddresses",
  },

  methods: {
    // Decrypts the AES encrypted data using the provided password.
    decryptData() {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(this.encryptedData, this.decryptPassword);
        this.decryptedData = CryptoJS.enc.Utf8.stringify(decryptedBytes).replace(/Key:/g, "Key:\n");
        this.showDecryptedData = true;
      } catch (err) {
        alert("Decryption failed. Please check encrypted data and password.");
      }
    },

    // Encrypts Ethereum data and downloads it as a text file.
    exportData() {
      // Construct the data string.
      let exportedData = `Mnemonic: ${this.mnemonic}\nDerive Path: ${this.derivePath}\nExtended Private Key: ${this.bip32extendedprivatekey}\nExtended Public Key: ${this.bip32extendedpublickey}\n\nAddresses:\n`;
      this.addresses.forEach((address, i) => {
        exportedData += `\n#${i + this.start}\nAddress: ${address.address}\nPrivate Key: ${address.privateKey}\n`;
      });

      // Encrypt the data.
      const encryptedData = CryptoJS.AES.encrypt(exportedData, this.password).toString();

      // Create a downloadable blob and trigger the download.
      const blob = new Blob([encryptedData], {type: "text/plain;charset=utf-8"});
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", new Date() + "_SatoshiTURK.Com.txt");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },

    // Generates a random mnemonic phrase.
    generateMnemonic() {
      this.mnemonic = ethers.utils.entropyToMnemonic(ethers.utils.randomBytes(16));
    },

    // Derives Ethereum addresses based on the mnemonic and range provided.
    async generateAddresses() {
      const walletArray = [];
      try {
        // Deriving BIP32 and BIP44 extended keys.
        const hdNode = ethers.utils.HDNode.fromMnemonic(this.mnemonic, this.password).derivePath(this.derivePath.slice(0, -1));
        this.bip32extendedprivatekey = hdNode.extendedKey;
        this.bip32extendedpublickey = hdNode.neuter().extendedKey;

        // Deriving BIP39 seed and root key.
        const bip39Seed = utils.mnemonicToSeed(this.mnemonic, this.password);
        this.bip39seed = bip39Seed;
        const bip39Root = utils.HDNode.fromSeed(bip39Seed);
        this.bip39root = bip39Root.extendedKey;

        // Deriving BIP44 extended keys.
        const bip44extendedprivatekey = bip39Root.derivePath(this.derivePath.slice(0, -3));
        this.bip44extendedprivatekey = bip44extendedprivatekey.extendedKey;
        this.bip44extendedpublickey = bip44extendedprivatekey.neuter().extendedKey;

        // Validating the start and end range.
        if (this.start < 0 || this.end < 1) {
          throw new Error("Invalid start and end values.");
        }

        // Generating Ethereum addresses.
        for (let i = this.start; i <= this.end; i++) {
          const derivedNode = await hdNode.derivePath( i.toString());
          const wallet = new ethers.Wallet(derivedNode.privateKey);
          walletArray.push({
            address: wallet.address,
            privateKey: wallet.privateKey,
            privateKeyMasked: '0x' + '*'.repeat(40),
            hideData: true,
          });
        }
        this.addresses = walletArray;
        this.error = "";
      } catch (err) {
        this.error = err.message;
      }
    },

    // Toggles the visibility of certain data.
    toggleData() {
      this.hideData = !this.hideData;
      this.addresses.forEach((address) => {
        address.showPrivateKey = !this.hideData;
        address.privateKeyMasked = address.showPrivateKey
            ? address.privateKey
            : "0x" + "*".repeat(40);
      });
    },

    // Copies the provided text to the clipboard.
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
          () => {
            alert("Private key successfully copied!");
          },
          (err) => {
            console.error(err);
            alert("Copy operation failed. Please try again.");
          }
      );
    },
  },

  // Lifecycle hook: Generates a mnemonic phrase when the component is created.
  created() {
    this.generateMnemonic();
  },
};
</script>
<style scoped>
@media (max-width: 767.98px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.full-screen-div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.close-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.full-screen-div pre {
  background-color: white;
  padding: 1rem;
  max-width: 80%;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 5px;
  text-align: left;
}
</style>